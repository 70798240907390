import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import { isSessionLive } from "@/utils/sessions"
import type { Session } from "@/types/api/program"

export default Vue.extend({
  props: {
    session: { type: Object as VuePropType<Session>, required: true },
  },

  computed: {
    ...mapGetters("user", ["isSessionFavorite"]),

    isFavorite: {
      get (): boolean { return this.isSessionFavorite(this.session.alias) },
      set (isFavorite: boolean) {
        const method = isFavorite
          ? "addFavoriteSession"
          : "removeFavoriteSession"
        this[method](this.session)
      },
    },

    trackName (): string {
      return this.$fmt.localize(this.session.track.hall.title)
    },

    trackDesc (): string {
      return this.$fmt.localize(this.session.track.description)
    },

    isLive (): boolean {
      const { session } = this
      return Boolean(session.track.liveData?.embedCode) &&
        isSessionLive(this.session)
    },
    liveLink (): string {
      return this.localePath(`/live/${this.session.track.hall.alias}`)
    },
    isLiveVisible (): boolean {
      return this.$route.path !== this.liveLink
    },
  },

  mounted () {
    this.$nuxt.$on("confirm-session-reg", this.onConfirm)
  },

  beforeDestroy () {
    this.$nuxt.$off("confirm-session-reg", this.onConfirm)
  },

  methods: {
    ...mapActions("user", ["addFavoriteSession", "removeFavoriteSession"]),

    toggle () {
      if (!this.$auth.loggedIn) {
        this.$nuxt.$emit("open-login")
        return
      }

      if (!this.isFavorite) {
        this.$nuxt.$emit("open-session-registration", this.session.id)
        return
      }

      this.isFavorite = !this.isFavorite
    },

    onConfirm (sessionId: string) {
      if (!this.isFavorite && this.session.id === sessionId)
        this.isFavorite = true
    },
  },
})
