// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6DmCVi3A{align-items:flex-start;display:flex}._6DmCVi3A>:not(:last-child){margin-right:var(--spacing-lg)}@media(max-width:667.98px){._6DmCVi3A>:not(:last-child){margin-right:var(--spacing-md)}}.hFMG57\\+2{border-radius:var(--border-radius);font-size:2rem;height:8rem;overflow:hidden;width:8rem}@media(max-width:667.98px){.hFMG57\\+2{font-size:1rem;height:5rem;width:5rem}}.Dp27k5Ps{word-wrap:anywhere;color:var(--color-bg-invert);font-size:1.5rem;font-weight:700;line-height:1.46;margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.Dp27k5Ps{font-size:1.25rem}}.-hS\\+ijfN{word-wrap:anywhere;font-size:1rem;font-weight:500;line-height:1.62}@media(max-width:667.98px){.-hS\\+ijfN{font-size:.88rem}}.u1El\\+z20{word-wrap:break-word;font-size:1rem;-webkit-hyphens:auto;hyphens:auto;line-height:1.62;padding-top:var(--spacing-lg)}@media(max-width:667.98px){.u1El\\+z20{font-size:.88rem;padding-top:var(--spacing-md)}}._7KC3HYWB,._6hDKqjis{margin-top:var(--spacing-lg)}@media(max-width:667.98px){._7KC3HYWB,._6hDKqjis{margin-top:var(--spacing-md)}}.kTm11FOA{display:none;padding-top:var(--spacing-sm);transition:.3s linear;transition-property:max-height}@media(max-width:667.98px){.kTm11FOA{padding-top:var(--spacing-s)}}.kTm11FOA>:not(:last-child){margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.kTm11FOA>:not(:last-child){margin-bottom:var(--spacing-s)}}._7KC3HYWB{align-items:center;color:var(--color-bg-invert);display:flex;font-size:1.25rem;font-weight:600;letter-spacing:.15px;line-height:1.5}@media(max-width:667.98px){._7KC3HYWB{font-size:1rem}}.v3y22pxf{display:block}.iai07ZG9{--size:1.2em;margin-left:.3em;transition:.1s linear;transition-property:transform}.aBdDRtCS{transform:rotate(90deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"top": "_6DmCVi3A",
	"photo": "hFMG57+2",
	"name": "Dp27k5Ps",
	"job": "-hS+ijfN",
	"bio": "u1El+z20",
	"sessionsHeading": "_7KC3HYWB",
	"spinner": "_6hDKqjis",
	"sessions": "kTm11FOA",
	"sessionsOpen": "v3y22pxf",
	"chevron": "iai07ZG9",
	"chevronDown": "aBdDRtCS"
};
module.exports = ___CSS_LOADER_EXPORT___;
